<template>
    <div>
        <app-page-header></app-page-header>
        <app-loader></app-loader>

        <v-container grid-list-xl>
            <v-form id="form_parking_permit" ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md10 lg10 xl8>
                        <v-card id="form_card">
                            <v-img
                            :src="imgParking"
                            height="500px"
                            ></v-img>
                            <v-card-title>
                                <v-icon left>mdi-file-document-edit-outline</v-icon>
                                <span class="subtitle-1 font-weight-regular application--card--title">Podatki o dovolilnici</span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card1')">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <!-- backend validation messages -->
                                <form-validation-messages :messages="apiValidationMessages">
                                </form-validation-messages>

                                <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>
                                <!--<br>
                                <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Pred nadaljevanjem izberite eno izmed spodnjih možnosti</p>-->
                                <v-radio-group v-model="row" row>
                                    <v-radio label="Dodaj novega uporabnika" value="radio-new-user"></v-radio>
                                    <v-radio label="Izberi obstoječega uporabnika" value="radio-existing-user"></v-radio>
                                </v-radio-group>
                                
                                <div v-show="!newUser" style="min-height:10px;"></div>
                                <p v-if="!newUser" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Iz podnjega seznama izberite uporabnika dovolilnice</p>
                                <!-- data table z imetniki-->
                                <div v-show="!newUser" style="margin-top:10px; margin-bottom:10px;">
                                    <select-permit-holder-data-table
                                    :settings="dataTableSettings"
                                    :queryParameters="queryParameters"
                                    ></select-permit-holder-data-table>   
                                </div>
                                <br v-show="!newUser">
                                <!-- imetnik -->
                                <v-text-field
                                    v-model="name"
                                    label="Ime in priimek"
                                    required
                                    :rules="name_rules"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- ulica -->
                                <v-text-field
                                    v-model="address"
                                    label="Naslov (prebivališče)"
                                    required
                                    :rules="address_rules"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- kraj -->
                                <v-text-field
                                    v-model="town"
                                    label="Kraj"
                                    required
                                    :rules="town_rules"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                 <!-- poštna številka -->
                                <v-text-field
                                    v-model="postal_code"
                                    label="Poštna številka"
                                    required
                                    :rules="postal_code_rules"
                                    type="number"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- izbira prebivališča -->
                                <v-select
                                :items="residenceTypes"
                                label="Tip prebivališča"
                                item-text="name"
                                item-value="id"
                                v-model="residence_type"
                                :rules="residence_type_rules"
                                :readonly="!newUser && (existing_permit_user !== null && existing_permit_user.residence_type !== null)"
                                :disabled="!newUser  && (existing_permit_user !== null && existing_permit_user.residence_type !== null)"
                                ></v-select>

                                <!-- telefonska številka -->
                                <v-text-field
                                    v-model="phone_number"
                                    label="Telefonska številka"
                                    required
                                    :rules="telephone_number_rules"
                                    type="number"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- emšo
                                <v-text-field
                                    v-model="emso"
                                    label="EMŠO"
                                    required
                                    :rules="emso_rules"
                                    type="number"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>-->

                                <!-- tip osebe (podjetje / fizična oseba) -->
                                    <v-select
                                :items="entities"
                                label="Izberi tip osebe"
                                item-text="name"
                                item-value="id"
                                v-model="entity"
                                :rules="entity_rules"
                                :readonly="!newUser && (existing_permit_user !== null && existing_permit_user.entity_type !== null)"
                                :disabled="!newUser  && (existing_permit_user !== null && existing_permit_user.entity_type !== null)"
                                ></v-select>

                                <!-- davčna številka -->
                                <v-text-field
                                    v-model="tax_number"
                                    label="Davčna številka"
                                    required
                                    :rules="tax_number_rules"
                                    v-if="requireTaxNumber"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- tip uporabnika -->
                                <v-select
                                :items="userTypes"
                                label="Tip uporabnika"
                                item-text="name"
                                item-value="id"
                                v-model="user_type"
                                :rules="user_type_rules"
                                ></v-select>

                                <!-- registrska številka -->
                                <v-row row wrap v-if="user_type !== 'f516b3cc-01d7-4657-827f-a51d55cc433d'">
                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-text-field
                                            v-model="registration_plate_number_1"
                                            label="Registrska številka 1"
                                            required
                                            :rules="(user_type == 'f516b3cc-01d7-4657-827f-a51d55cc433d') ? [] : []"
                                            :disabled="!newUser && !existing_permit_user"
                                            clearable
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-text-field
                                            v-model="registration_plate_number_2"
                                            label="Registrska številka 2 (neaktivna, se ne upošteva)"
                                            required
                                            :disabled="!newUser && !existing_permit_user"
                                            clearable
                                        ></v-text-field>
                                    </v-flex>
                                </v-row>
                                <!-- vozilo v osebni lasti ali lasti podjetja -->
                                <v-select
                                v-if="user_type !== 'f516b3cc-01d7-4657-827f-a51d55cc433d'"
                                :items="vehicleOwnershipTypes"
                                label="Lastništvo vozila"
                                item-text="name"
                                item-value="id"
                                v-model="vehicle_ownership"
                                :rules="(user_type == 'f516b3cc-01d7-4657-827f-a51d55cc433d') ? [] : vehicle_ownership_rules"
                                :disabled="!newUser && !existing_permit_user"
                                ></v-select>
                            </v-card-text>

                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-cogs</v-icon>
                                <v-toolbar-title class="body-2">
                                    Nastavitve
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card2')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-show="card2">
                                <!-- status dovolilnice -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <!-- ID KARTICE -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Status dovolilnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            :items="permitStatuses"
                                            label="Status dovolilnice"
                                            item-text="name"
                                            item-value="id"
                                            v-model="permit_status"
                                            :rules="permit_status_rules"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>

                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                                >   
                                <v-icon left>mdi-calendar-month-outline</v-icon>
                                <v-toolbar-title class="body-2">Datumska omejitev</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card3')">
                                    <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card3"></v-divider>
                            <v-card-text v-show="card3">
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Veljavnost dovolilnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        label="Datum od"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        label="Datum do"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>

                                    <!-- nastavi datume -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                            <v-subheader><strong></strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-left>
                                            <!--<v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly"  color="blue-grey darken-1" dark @click="setDateRange()"><v-icon left>mdi-calendar-range</v-icon>{{ permitDateRangeText() }}</v-btn>-->
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly"  color="blue-grey darken-1" dark @click="setDateRange()"><v-icon left>mdi-calendar-range</v-icon>Veljavnost 12 mesecev</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container> 
                            </v-card-text>

                            <!-- parkirni avtomati -->
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-parking</v-icon>
                                <v-toolbar-title class="body-2">Določitev parkirnih avtomatov</v-toolbar-title>
                            <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card4')">
                                    <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card4"></v-divider>
                            <v-card-text v-show="card4">
                                <v-container grid-list-xl text-x-center align-center v-if="vip">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-center>
                                            <span style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-check-circle</v-icon>Izbrani tip dovolilnice ima omogočeno uporabo vseh parkirnih avtomatov.</span>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center v-if="!noUserTypeSelected && !vip">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-center>
                                            <span v-if="selected_parking_lots.length > 0" style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-check-circle</v-icon>Izbrani tip dovolilnice ima omogočeno uporabo naslednjih parkomatov:</span>
                                            <span v-else style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="error" left>mdi-alert-circle</v-icon>Za izbrani tip dovolilnice ni določenih parkomatov.</span>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center v-if="!noUserTypeSelected && !vip && selected_parking_lots.length > 0">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-center>
                                            <chips :items="selected_parking_lots" color="" icon="mdi-parking"></chips>
                                        </v-flex>
                                    </v-layout>
                                </v-container>



                                <v-container grid-list-xl text-x-center align-center v-if="noUserTypeSelected">
                                    <v-layout row justify-start v-bind="columnBinding">
                                        <v-flex xs12 align-content-end>
                                            <v-switch
                                                v-model="all_parking_lots"
                                                :label="`Dovoli uporabo vseh parkirišč: ${allowAllParkingLots}`"
                                                color="secondary"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>
                                    <div style="min-height:7px;"></div>
                                </v-container>

                                <!-- parkomati -->
                                <v-container grid-list-xl text-x-center align-center v-if="noUserTypeSelected">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-2 pl-0"><strong>Izberi parkomate</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_parking_lots"
                                            :items="parkingLots"
                                            label="Izberi parkomate"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-parking"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-credit-card-wireless-outline</v-icon>
                                <v-toolbar-title class="body-2">RFID kartica</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card5')">
                                    <v-icon>{{ card5 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card5"></v-divider>
                            <v-card-text v-show="card5">
                                <v-container grid-list-xl text-x-center align-center>
                                    <!-- ID KARTICE -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_id"
                                                label="ID kartice"
                                                required
                                                :disabled="!card_id_input_enabled"
                                                ref="cardId"
                                                :rules="getCardNumber ? card_id_rules : []"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <!-- serijska št. kartice -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Serijska številka kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="card_number"
                                                label="Serijska št. kartice"
                                                required
                                                :disabled="!card_number_input_enabled"
                                                ref="cardNumber"
                                                @keyup.enter.native="RfidNr"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <!-- blokiraj kartico -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center >
                                            <v-subheader class="pt-7 pl-0"><strong>Kartica blokirana</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-switch
                                                v-model="card_blocked"
                                                label="Blokiraj kartico"
                                                color="secondary"
                                                value="1"
                                                hide-details
                                            ></v-switch>
                                        </v-flex>
                                    </v-layout>

                                    <!-- btns -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                            <v-subheader><strong></strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-left>
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" :disabled="storePermitLoading " :loading="scanRfidLoading" color="success" @click="enableRfidCardInput"><v-icon left>mdi-wifi</v-icon>Klikni tukaj za skeniranje</v-btn>
                                            &nbsp;&nbsp;
                                            <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" :disabled="scanRfidLoading ||storePermitLoading" color="error" @click="removeRfidCard"><v-icon left>mdi-close</v-icon>Odstrani kartico</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.mdAndDown">
                                <v-btn @click="submitForm()" color="success" block outlined style="margin-bottom:10px;" :disabled="!newUser && !existing_permit_user"><v-icon left>mdi-arrow-right</v-icon>Ustvari</v-btn>
                                <v-btn @click="clearForm()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-text>
                            <v-card-actions v-else>
                                <v-btn @click="submitForm()" color="success" text :disabled="!newUser && !existing_permit_user"><v-icon left>mdi-arrow-right</v-icon>Ustvari</v-btn>
                                <v-btn @click="clearForm()" color="error" text><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import imgCardParking from '@/assets/card_blejska_kartica.png'
import { formatDate, currentDate, oneYearLater } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
const SelectPermitHolderDataTable = () => import('@/components/admin/SelectPermitHolderDataTable.vue')
import PermitService from '@/services/permit.service.js'

export default {
    components: {
        FormValidationMessages,
        FormSubmitMessages,
        SelectPermitHolderDataTable
    },
    
    data: () => ({
        dataTableSettings: {
            id: 'dtSelectPermitHolder',
            title: 'Seznam imetnikov dovolillnic',
            sortColumn: ['name'],
            sortDirection: ['asc'],
            progressHeight: 3,
            headers: [
                //{ text: "ID", value: "id", align: "left", visibility: true},
                { text: "Naziv", value: "name", visibility: true},
                { text: "Naslov", value: "address", visibility: true},
                { text: "Poštna št.", value: "postal_code", visibility: true},
                { text: "Kraj", value: "town", visibility: true},
                { text: "Tel. št.", value: "phone_number", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/admin/permit-holders',
            editItem: {
                route: '',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_select_permit_holders',
                totalItems : {
                    event: ''
                },
                search: 'dt-select-permit-holders-search',
                toggleColumnsId: 'toggle-select-permit-holders-list-columns',
                selectedItem: 'dt-parking-permit-selected-item',
                removeSelectedItem: 'dt-parking-permit-remove-selected-item'
            }


        },

        card1: true,
        card2:true,
        card3: true,
        card4: true,
        card5: true,
        row: "radio-new-user",
        permit_holders: [],
        existing_permit_user: null,

        valid: false,
        user_type: null,
        name: null,
        address: null,
        town: null,
        postal_code: null,
        residence_type: null,
        temporary_residence_address: null,
        telephone_number: null,
        tax_number: null,
        subject_type: null,
        email: null,
        phone_number: null,
        emso: null,
        entity: null,
        registration_plate_number_1: null,
        registration_plate_number_2: null,
        vehicle_ownership: null,
        restriction_start_date: null,
        restriction_end_date:null,

        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,
        all_parking_lots: false,
        selected_parking_lots: [],
        card_id: null,
        card_id_input_enabled: false,
        card_number: null,
        card_number_input_enabled: false,
        card_scan_input: null,
        card_blocked: false,
        permit_status: null,

        scanRfidLoading: false,
        storePermitLoading: false,

        userTypes: [],
        residenceTypes: [],
        entities: [],
        vehicleOwnershipTypes: [],
        permitStatuses: [],
        parkingLots: [],
        userTypeParkingLots: [],

        user_type_rules: [v => !!v || 'Polje tip uporabnika je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        address_rules: [v => !!v || 'Polje naslov prebivališča je obvezno'],
        town_rules: [v => !!v || 'Polje kraj je obvezno'],
        postal_code_rules: [v => !!v || 'Polje poštna številka je obvezno'],
        residence_type_rules: [v => !!v || 'Polje tip prebivališča je obvezno'],
        telephone_number_rules: [v => !!v || 'Polje telefonska številka je obvezno'],
        email_rules: [
            v => !!v || 'Polje e-mail je obvezno',
            v => /.+@.+\..+/.test(v) || 'Vnesen elektronski naslov ni pravilen'
        ],
        emso_rules: [v => !!v || 'Polje emso je obvezno'],
        tax_number_rules: [v => !!v || 'Polje davčna številka je obvezno'],
        entity_rules: [v => !!v || 'Polje tip osebe je obvezno'],
        registration_plate_number_rules: [
            v => !!v || 'Polje registrska številka vozila je obvezno',
           // v => ^[a-zA-Z0-9]+$.test(v) || 'Registrska številka lahko vsebuje le številke in črke. Ostali znaki in presledki niso dovoljeni!'
        ],
        
        vehicle_ownership_rules: [v => !!v || 'Polje lastništvo vozila je obvezno'],
        permit_status_rules: [v => !!v || 'Polje status dovolilnice je obvezno'],
        card_id_rules: [
            v => (!!v) || 'Vnos ID kartice je obvezen!',
        ],

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],
        vip: false,
        noUserTypeSelected: true

    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        row(newValue, oldValue) {
            this.existing_permit_user = null
            this.clearForm()
        },

        existing_permit_user(value){
            let vm = this
            if(value != null && value !== undefined && value != '') {
                this.user_type = null
                vm.name = value.name
                vm.address = value.address
                vm.town = value.town
                vm.postal_code = value.postal_code
                vm.residence_type = (value.residence_type !== null) ? value.residence_type.uuid : null
                vm.phone_number = value.phone_number
                vm.emso = value.emso
                vm.entity = (value.entity_type !== null) ? value.entity_type.uuid : null
                vm.tax_number = value.tax_number
                
            }

            if(value == null || value === undefined) {
                this.name = null
                this.address = null
                this.town = null
                this.postal_code = null
                this.residence_type = null
                this.phone_number = null
                this.emso = null
                this.entity = null
                this.tax_number = null
                this.$refs.form.resetValidation();
            }
        },

        user_type(value) {
            window.console.log("Selected user type: ", value)
            if(value ==  'f3698d5e-7c1d-4dde-97f6-676b811af1cb') {
                this.vip = true       
            } else {
                this.vip = false
            }

            if(value == 'd51c8079-415f-4d31-bd2c-8e45e48b345c' || value == null) {
                this.noUserTypeSelected = true
            } else {
                this.noUserTypeSelected = false
            }

            if(value == 'f516b3cc-01d7-4657-827f-a51d55cc433d') {
                this.registration_plate_number_1 = "";
                this.registration_plate_number_2 = "";
                this.vehicle_ownership = "";
            }

            this.selected_parking_lots = PermitService.setUserTypeParkingLots(value, this.userTypeParkingLots)
        },

        registration_plate_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_plate_number_1)
                this.registration_plate_number_1 = this.registration_plate_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_plate_number_1 = this.registration_plate_number_1.toUpperCase();
            }
        },

        registration_plate_number_2(value) {
            if(value != null) {
                //window.console.log(this.registration_plate_number_1)
                this.registration_plate_number_2 = this.registration_plate_number_2.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_plate_number_2 = this.registration_plate_number_2.toUpperCase();
            }
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        requireTaxNumber() {
            if(this.entity == "74c968fc-f8f8-4596-b1f4-3616b4c2c029") {
                return true
            }

            return false
        },

        imgParking() {
            return imgCardParking
        },

        getCardNumber() {
           if(this.card_number == null || this.card_number == '') return false

           return true
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        newUser() {
            if(this.row == 'radio-new-user') {
                return true
            }

            return false
        },

        queryParameters() {
            return {
            }
        },

        allowAllParkingLots() {
            if(this.all_parking_lots) {
                return 'DA'
            }

            return 'NE'
        }
    },

    methods: {
        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        toggleCardContent(content) {
            if(content === 'card1') {
                this.card1 = !this.card1
            }

            if(content === 'card2') {
                this.card2 = !this.card2
            }

            if(content === 'card3') {
                this.card3 = !this.card3
            }

            if(content === 'card4') {
                this.card4 = !this.card4
            }

            if(content === 'card5') {
                this.card5 = !this.card5
            }
        },

        submitForm() {
            
            window.console.log(this.createDataObject());
            var vm = this
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []

            if(this.$refs.form.validate()) {
                vm.loading = true
                vm.$store.commit('SET_LOADER_TEXT', 'Kreiranje dovolilnice je v teku...')
                vm.$store.commit('SET_LOADER', true)
                vm.$store.dispatch('CREATE_PARKING_PERMIT', vm.createDataObject())
                .then(response => {
                    setTimeout(() => {
                        vm.backendMessages.push('Dovolilnica je bila uspešno kreirana.')
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                        vm.$store.commit('SET_SNACKBAR_TEXT', "Dovolilnica je bila uspešno kreirana")
                        vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                        vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    }, 700)

                    vm.clearForm();
                })
                .catch(error => {
                    window.console.log(error)
                    window.console.log(error.response)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)

                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri kreiranju dovolilnice je prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }

                })
                .then(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    }, 600)
                    vm.loading = false
                })
            } 
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }
            
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.user_type = null
            this.registration_plate_number_1 = null
            this.registration_plate_number_2 = null
            this.vehicle_ownership = null
            this.restriction_start_date = null
            this.restriction_end_date = null
            this.all_parking_lots = false
            this.selected_parking_lots = []
            this.permit_status = null
            this.card_id = null
            this.card_number = null
            this.card_scan_input = null
            this.card_blocked = false

            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []

            this.existing_permit_user = null
            if(this.dataTableSettings.eventBus.removeSelectedItem != null && this.dataTableSettings.eventBus.removeSelectedItem != '') {
                EventBus.$emit(this.dataTableSettings.eventBus.removeSelectedItem, null);
            }

        },

        createDataObject() {
            let selectedParkingLots = [];
            if(this.selected_parking_lots != null && this.selected_parking_lots != undefined) {
                this.selected_parking_lots.forEach(parkingLot => {
                    selectedParkingLots.push(parkingLot.id)
                })
            }

            return {
                permit_holder_uuid: (this.existing_permit_user != null) ? this.existing_permit_user.uuid : null,
                user_type: this.user_type,
                name: this.name,
                address: this.address,
                town: this.town,
                postal_code: this.postal_code,
                residence_type: this.residence_type,
                phone_number: this.phone_number,
                emso: this.emso,
                tax_number: this.tax_number,
                entity: this.entity,
                registration_plate_number_1: this.registration_plate_number_1,
                registration_plate_number_2: this.registration_plate_number_2,
                vehicle_ownership: this.vehicle_ownership,
                permit_status: this.permit_status,
                restriction_start_date: this.restriction_start_date,
                restriction_end_date: this.restriction_end_date,
                all_parking_lots: (this.all_parking_lots == 1 || this.all_parking_lots == true) ? '1' : '0',
                selected_parking_lots: selectedParkingLots,
                card_id: this.card_id,
                card_number: this.card_number,
                card_scan_input: this.card_scan_input,
                card_blocked: (this.card_blocked == 1 || this.card_blocked == true) ? '1' : '0'


            }
        },

        getAdminResources() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('ADMIN_RESOURCES')
            .then(response => {
                window.console.log(response.data)

                if(response.data.hasOwnProperty('user_types')) {
                    vm.$store.commit('SET_USER_TYPES', response.data.user_types)
                    response.data.user_types.forEach(element => {
                        if(element.hasOwnProperty('type')) {
                            if(
                                element.type == 1 || element.type == 2 ||
                                element.type == 9 || element.type == 10 ||
                                element.type == 11 || element.type == 12 ||
                                element.type == 13 || element.type == 14 ||
                                element.type == 15 || element.type == 8 || element.type == 20 || element.type == 22 || element.type == 21 ||element.type == 23 || element.type == 24 || element.type == 25 || element.type == 26 || element.type == 27
                            ) {
                                vm.userTypes.push(element)
                            }
                        }
                    })
                }

                if(response.data.hasOwnProperty('residence_types')) {
                    vm.$store.commit('SET_RESIDENCE_TYPES', response.data.residence_types)
                    vm.residenceTypes = response.data.residence_types
                }

                if(response.data.hasOwnProperty('entities')) {
                    vm.$store.commit('SET_ENTITIES', response.data.entities)
                    vm.entities = response.data.entities
                }

                if(response.data.hasOwnProperty('vehicle_ownership_types')) {
                    vm.$store.commit('SET_VEHICLE_OWNERSHIP_TYPES', response.data.vehicle_ownership_types)
                    vm.vehicleOwnershipTypes = response.data.vehicle_ownership_types
                }
                
                if(response.data.hasOwnProperty('permit_statuses')) {
                    vm.$store.commit('SET_PERMIT_STATUSES', response.data.permit_statuses)
                    vm.permitStatuses = response.data.permit_statuses
                }

                if(response.data.hasOwnProperty('parking_lots')) {
                    vm.$store.commit('SET_PARKING_LOTS', response.data.parking_lots)
                    vm.parkingLots = vm.$store.getters.parkingLots
                }

                if(response.data.hasOwnProperty('user_type_parking_lots')) {
                    vm.userTypeParkingLots = response.data.user_type_parking_lots
                    //window.console.log(vm.userTypeParkingLots)
                }

            })
            .catch(error => {
                window.console.error("### ERROR ParkingPermit@getAdminResources")
                window.console.error(error)
            })
            .finally(() =>{
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                }, 1000)
            })
        },

        RfidNr() {
            let data = this.card_number
            this.card_scan_input = this.card_number
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.scanRfidLoading = true
            this.$store.dispatch('CONVERT_RFID_NR', {nr: data})
            .then(response => {
                //window.console.log(response.data)
                //window.console.log(response.rfidNr)
                this.card_number = response.data.rfidNr
                this.scanRfidLoading = false
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Kartica uspešno poskenirana. Serijska številka kartice: " +  response.data.rfidNr + ". Vpišite še ID kartice.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

                this.card_id_input_enabled = true
                setTimeout(() => {
                        vm.$refs.cardId.focus()
                    }, 350)

            })
            .catch(error => {
                window.console.log('RfidNr error')
                window.console.log(error)
                this.scanRfidLoading = false    
                this.removeRfidCard()
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', 'Pri skeniranju kartice je prišlo do napake. Poskusite ponovno.')
                vm.$store.commit('SET_SNACKBAR_MODE', '')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)

            })
            .finally(() => {
                vm.card_number_input_enabled = false
                vm.$store.commit('SET_PROGRESS', false)
            })

            this.cardNumberInputEnabled = false
        },

        removeRfidCard() {
            this.card_id = null;
            this.card_number = null;
            this.card_scan_input = null;
            this.card_blocked = null;

            this.card_id_input_enabled = false;
            this.card_number_input_enabled = false;
            //this.$refs.form.validate();
            this.$refs.cardId.blur();
            this.$refs.cardNumber.blur();

        },

        enableRfidCardInput() {
            this.removeRfidCard()
            this.card_number_input_enabled = true

            setTimeout(() => {
                this.$refs.cardNumber.focus()
            }, 350)
        },

        setDateRange() {
            this.permit_status = process.env.VUE_APP_PERMIT_STATUS_ACTIVE
            //this.restriction_start_date = process.env.VUE_APP_PERMIT_DATE_RANGE_START
            //this.restriction_end_date = process.env.VUE_APP_PERMIT_DATE_RANGE_END
            this.restriction_start_date = currentDate()
            this.restriction_end_date = oneYearLater()
        },

        permitDateRangeText() {
            return process.env.VUE_APP_PERMIT_BTN_DATE_RANGE_TEXT
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {
        window.console.log("### Admin\ParkingPermit.vue ###");

        let vm = this
        this.getAdminResources();
        if(this.dataTableSettings.eventBus.selectedItem != null && this.dataTableSettings.eventBus.selectedItem != '') {
            //window.console.log(this.dataTableSettings.eventBus.selectedItem);
            EventBus.$on(this.dataTableSettings.eventBus.selectedItem, item => {
                //window.console.log(item)
                if(Array.isArray(item) && item.length > 0) {
                    vm.existing_permit_user = item[0]
                } else {
                    vm.existing_permit_user = null
                }
            })
        }
    },

    beforeDestroy() {
        if(this.dataTableSettings.eventBus.selectedItem != null && this.dataTableSettings.eventBus.selectedItem != '') {
            EventBus.$off(this.dataTableSettings.eventBus.selectedItem)
        }
    },

    destroyed() {
        
    }
}

</script>

<style scoped>
    .btn_rfid {
        margin-right:10px;
        margin-top: 10px;
    }
</style>